import React, {Component} from 'react';
//import Breadcrumb from "../common/breadcrumb";
import OtpInput from 'react-otp-input';
import {Link} from 'react-router-dom'
import PinInput from "react-pin-input";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { lab } from 'color-convert';

class Login2 extends Component {

    constructor (props) {
        super (props);
        this.handleLogin = this.handleLogin.bind(this);
        //this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            showWelcome : true,
            showSms : false,
            showPassword : false,
            bgColor1: "white",
            bgColor2: "#white",
            bgColor3: "#white",
            step1: '1_',
            step2: '2.',
            step3: '3.',
            otp: '',
            opacity:'1',
            opacity2:'0.2',
            opacity3:'0.2',
            value: '',
            phone: '',
            password: '',
            showForm: true,
        };
    }

    componentWillMount() {

      
     //document.body.style = `background: linear-gradient(318.35deg, #1A73DB -41.04%, #59D1EB 122.69%);`;
     localStorage.removeItem("login-message");
      setTimeout(function() {
        document.querySelector(".loader-wrapper").style = "display: none";
      }, 2000);

      this.checkOpenWeb();

      if(AuthService.getCurrentUser()){
          this.props.history.push("/welcome");
          window.location.reload();
        }
    }

    checkOpenWeb(){
      //var user = AuthService.getCurrentUser();
      ApiService.checkOpenWeb().then(
          (res) => {
            if(res && res.status == 'success'){
                  //console.log('res.data',res.data);
              if(res.data.option_value == 'NO'){
                //this.props.history.push("/closed-web")
                //window.location.reload();
                this.setState({
                  showForm: false
                });
                  
              }
            } else if(res.status == 'error'){
              this.setState({
                showForm: false
              });
            } 
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
  }

    onChangePhone = (e) => {
      let val = e.target.value
      let max = 9
      let vale_lenght = val.toString().length
      if(vale_lenght <= max){
        this.setState({
          phone: val
        });
      }
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
      }

    onChange = value => {
        //console.log(this.pin);
        //console.log(value);
        this.setState({ pin_code: value });
        //console.log(this.state);
      };
    
      onClear = () => {
        this.setState({
          value: ""
        });
        this.pin.clear();
      };
    
    handleChange = otp => this.setState({ otp });  

    btnWelcome() {

        if(!this.state.phone){
            return true;
        }

        let data_user = {
            'cell_phonenumber': this.state.phone,
        }

        //document.getElementsByClassName("page-loader")[0].style.display = "flex";

        ApiService.sendPhone(data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){

                      //document.getElementsByClassName("page-loader")[0].style.display = "none";

                        this.setState({
                            showWelcome:!this.state.showWelcome,
                            showSms:!this.state.showSms,
                            bgColor1: "white",
                            bgColor2: "white",
                            bgColor3: "white",
                            step1: '1.',
                            step2: '2_',
                            step3: '3.',
                            opacity:'0.2',
                            opacity2:'1',
                            opacity3:'0.2',
                        })

                        //toast.success("Usuario creado!");
                        //this.props.history.push("users/list-user");
                    }else{
                      //document.getElementsByClassName("page-loader")[0].style.display = "none";
                      this.props.history.push("/unauthorized");
                      //toast.error(res.message);
                    }
                }
            },
            error => {

              //document.getElementsByClassName("page-loader")[0].style.display = "none";

              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
    }

    btnSms() {
        this.setState({
            showSms:!this.state.showSms,
            showPassword:!this.state.showPassword,
            bgColor1: "white",
            bgColor2: "white",
            bgColor3: "white",
            step1: '1.',
            step2: '2.',
            step3: '3_',
            opacity:'0.2',
            opacity2:'0.2',
            opacity3:'1',
        })
    }

    btnStep1() {
        return false;
        /*this.setState({
            showWelcome:true,
            showSms:false,
            showPassword:false,
            bgColor1: "white",
            bgColor2: "white",
            bgColor3: "white",
            step1: '1_',
            step2: '2.',
            step3: '3.',
            opacity:'1',
            opacity2:'0.2',
            opacity3:'0.2',
        })*/
    }

    btnStep2() {
        return false;
        /*this.setState({
            showWelcome:false,
            showSms:true,
            showPassword:false,
            bgColor1: "white",
            bgColor2: "white",
            bgColor3: "white",
            step1: '1.',
            step2: '2_',
            step3: '3.',
            opacity:'0.2',
            opacity2:'1',
            opacity3:'0.2',
        })*/
    }

    btnStep3() {
        return false;
        /*this.setState({
            showWelcome:false,
            showSms:false,
            showPassword:true,
            bgColor1: "white",
            bgColor2: "white",
            bgColor3: "white",
            step1: '1.',
            step2: '2.',
            step3: '3_',
            opacity:'0.2',
            opacity2:'0.2',
            opacity3:'1',
        })*/
    }

    btnPassword() {
        //console.log('Send data');
    }

    handleLogin(e) {

      
      //document.getElementsByClassName("page-loader")[0].style.display = "flex";
    
      AuthService.login(this.state.phone, this.state.password, this.state.pin_code).then(
            (res) => {
                if(res && res.status == 'success'){
                    //document.getElementsByClassName("page-loader")[0].style.display = "none";
                    //console.log(res)
                    localStorage.removeItem("login-message");
                    this.props.history.push("/welcome");
                    //window.location.reload();
                }else{
                  //document.getElementsByClassName("page-loader")[0].style.display = "none";
                  localStorage.setItem("login-message", res.message);
                  this.props.history.push("/unauthorized");
                }
              //this.props.history.push("/dashboard");
              //window.location.reload();
            },
            error => {

              //document.getElementsByClassName("page-loader")[0].style.display = "none";
              
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
    
       
      }


    render (){

        document.body.classList.remove('dark');

        return (
            <div className="sup-content-login">
                {/*<Breadcrumb title={'Login'}/>*/}
                
                {/*Login section*/}
                    <div className="container container-sms-login">
                                {/*<h3>Login</h3>*/}
                                {/*<div className="theme-card">*/}
                                    <Form 
                                    className="theme-form form-login" 
                                    ref={c => {this.form = c;}}
                                    onSubmit={e => { e.preventDefault(); }}
                                    >
                                      <div className="logo-cruz" style={{ display: 'grid' }}>
                                          <img src="/assets/images/logo_cruz.png" className="img-logo-cruz"></img>
                                          <span className="ver-login-ui">Uiuid</span>
                                      </div>

                                        {  
                                          this.state.showForm == false?
                                            <div>
                                            <h2 className="title-div h2-welcome-text">¡Aviso!</h2>
                                            <h2 className="title-div h2-welcome-text">La Página se encuentra cerrada.</h2>
                                            
                                            
                                          </div>
                                          :null
                                        } 
                                        {
                                            this.state.showWelcome && this.state.showForm ? 
                                            <div>
                                                <h2 className="title-div h2-welcome-text">BIENVENIDO</h2>
                                                <div className="form-group form-group-login">
                                                    <input 
                                                        name="phone" 
                                                        value={this.state.phone}
                                                        className="form-control input-phone" 
                                                        placeholder="INGRESA TU NÚMERO" 
                                                        id="phone" 
                                                        onChange={this.onChangePhone}
                                                        type="number"
                                                        min="0"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        //validations={[required]}
                                                    />
                                                    {/*<input type="text" className="form-control input-phone" id="phone" placeholder="# CELULAR"
                                                    required="" />*/}
                                                </div>
                                                <a onClick={()=>this.btnWelcome()} >
                                                    <img src="/assets/images/boton_off.png" className="img-btn-step"
                                                    onMouseOver={e => (e.currentTarget.src = "/assets/images/boton_hover.png")}
                                                    onMouseOut={e => (e.currentTarget.src = "/assets/images/boton_off.png")}
                                                    />
                                                </a>
                                            </div>
                                            :null
                                        }
                                        {
                                            this.state.showSms?
                                            <div>
                                                <label htmlFor="phone" className="text-div">TE ENVÍAMOS UN CÓDIGO AL WHATSAPP</label>
                                                <h2 className="title-div h2-code-text">INGRESALO AQUÍ</h2>
                                                <div className="form-group form-group-login">
                                                    {/*<div className="txt-sms-code">*/}
                                                        <PinInput
                                                            length={4}
                                                            focus
                                                            ref={p => (this.pin = p)}
                                                            type="numeric"
                                                            onChange={this.onChange}
                                                            min="0"
                                                            inputMode="number"
                                                            pattern="[0-9]*"
                                                            />
                                                    {/*</div>*/}
                                                </div>
                                            <a onClick={()=>this.btnSms()} >
                                                <img src="/assets/images/boton_off.png" className="img-btn-step"
                                                onMouseOver={e => (e.currentTarget.src = "/assets/images/boton_hover.png")}
                                                onMouseOut={e => (e.currentTarget.src = "/assets/images/boton_off.png")}
                                                />  
                                            </a>
                                            {/*<div style={{marginTop: '2rem',fontSize: '12px'}} className="text-div">
                                              <a href="#" style={{marginRight: '0.5rem'}}>REENVIAR CODIGO</a>
                                              <span>30</span>
                                            </div>*/}
                                            </div>
                                            :null                                            
                                        }
                                        {
                                            this.state.showPassword?
                                            <div>
                                                <label htmlFor="phone" className="text-div">INGRESA TU</label>
                                                <h2 className="title-div h2-password-text">CONTRASEÑA</h2>
                                                <div className="form-group form-group-login">
                                                    <Input 
                                                        name="password" 
                                                        type="password" 
                                                        id="password"
                                                        className="form-control input-password" 
                                                        placeholder="CONTRASEÑA" 
                                                        value={this.state.password}
                                                        onChange={this.onChangePassword}
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        //validations={[required]}
                                                    />
                                                    {/*<input type="password" className="form-control input-password" id="review"
                                                    placeholder="CONTRASEÑA" required="" />*/}
                                                </div>
                                                <a
                                                    style={{background: 'transparent', border: 'none'}}
                                                    onClick={() => this.handleLogin()}
                                                >
                                                    <img src="/assets/images/boton_off.png" className="img-btn-step"
                                                    onMouseOver={e => (e.currentTarget.src = "/assets/images/boton_hover.png")}
                                                    onMouseOut={e => (e.currentTarget.src = "/assets/images/boton_off.png")}
                                                    />   
                                                </a>
                                                {/*<a className="btn btn-solid btn-login" onClick={()=>this.btnPassword()} >{'>'}</a>*/}
                                            </div>
                                            :null
                                        }
                                     
                                        <div className="container-btn-steps" style={{width: '100%', textAlign: 'center'}}>
                                          <div className="btn-steps">
                                              <a className="btn btn-step" style={{opacity: this.state.opacity, color:this.state.bgColor1}} onClick={()=>this.btnStep1()} >{this.state.step1}</a>
                                              <a className="btn btn-step" style={{opacity: this.state.opacity2, color:this.state.bgColor2}} onClick={()=>this.btnStep2()} >{this.state.step2}</a>
                                              <a className="btn btn-step" style={{opacity: this.state.opacity3, color:this.state.bgColor3}} onClick={()=>this.btnStep3()} >{this.state.step3}</a>
                                          </div> 
                                        </div>
                                     
                                    </Form>
                                {/*</div>*/}                           
                    </div>
                    <div className="image-right">
                            <img src="/assets/images/plant_login.png"></img>
                    </div>
            </div>
        )
    }
}

export default Login2